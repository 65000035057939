import React, { useEffect, useState } from 'react';
import useCacheState from '../../../hooks/useCacheState';
import VisitorsOverviewWidget from './VisitorsOverviewWidget';
import {
  generatechartoptions,
  generatechartoptionsmultipleseries,
  generate_data_frombigquery
} from '../utils';
import { supabase } from '../../../services/supabase';

function BlackChart({ magnetuuid, filterdate, community_id }) {
  const [launchDate, setLaunchDate] = useState();
  // Combined analytics state stored in our cache (or local state).
  const [analytics, setAnalytics] = useCacheState(
    {
      totaltours: 0,
      uniquevisitor: 0,
      blackChartOptions: undefined,
    },
    'analytics',
    community_id
  );
  // Create an accumulator object using useState.
  // We never call its setter so that updates to this object do not trigger re-renders.
  const [accumulatedAnalytics] = useState({
    totaltours: 0,
    uniquevisitor: 0,
    blackChartOptions: undefined,
  });

  useEffect(() => {
    if (!magnetuuid) return;

    (async () => {
      const { data: communityData, error } = await supabase
        .from('Community')
        .select('launchDate')
        .eq('community_magnets', magnetuuid);
      if (error) {
        console.error('Error fetching launchDate:', error);
        return;
      }
      const launchdate = communityData?.[0]?.launchDate;
      setLaunchDate(launchdate);
      console.log('launchdate', launchdate);

      generate_data_frombigquery(
        filterdate,
        magnetuuid,
        // Callback for totaltours.
        (tours) => {
          accumulatedAnalytics.totaltours = tours;
        },
        () => {},
        // Callback for uniquevisitor.
        (unique) => {
          accumulatedAnalytics.uniquevisitor = unique;
        },
        generatechartoptions,
        () => {},
        generatechartoptionsmultipleseries,
        () => {},
        // Final callback for blackChartOptions.
        (chartOptions) => {
          accumulatedAnalytics.blackChartOptions = chartOptions;
          // Update our combined analytics state in one go.
          setAnalytics({ ...accumulatedAnalytics });
        },
        launchdate
      );
    })();
  }, [magnetuuid, filterdate, community_id]);

  return (
    <VisitorsOverviewWidget
      blackChartOptions={analytics?.blackChartOptions}
      uniquevisitor={analytics?.uniquevisitor}
      totaltours={analytics?.totaltours}
      magnetuuid={magnetuuid}
      launchDate={launchDate}
      setlaunchDate={setLaunchDate}
    />
  );
}

export default BlackChart;
